import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"


const TeacherListTemplate = ({ data, location, pageContext }) => {


  const teachers = data.teacherList.edges.sort((a,b) =>  {
              const orderA = (
                (a.node.data.SortOrder && a.node.data.SortOrder) || 0);
              const orderB = ((b.node.data.SortOrder && b.node.data.SortOrder) || 0)
              const comp = -(orderA-orderB);
              return comp });

  return (
    <Layout location={location} title="Social List">
      <Seo
        title={`Dance Classes ${pageContext.regionPronoun} ` + pageContext.region}
        description={`Find dance calsses ${pageContext.regionPronoun} ` + pageContext.region}
        image="https://dl.airtable.com/.attachments/317d552293769d007838f38ceaeeb527/41251728/SocialDance.jpg"
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >

        <h1>Dance Classes {pageContext.regionPronoun} {pageContext.region}</h1>

        <div className="navigator"> <Link to={"/socials/"+pageContext.regionSlug}>Socials</Link> <span className="nav-sep">|</span> <span className="nav-current">Classes</span></div>


        <h5 className="social-day">
        Teachers
        </h5>

        <section>
        {teachers.map(teacher => {
          return <div key={teacher.node.data.RecordID}>

            <Link to={"/teachers/"+teacher.node.data.RecordID}>
              <div className="dance-row" style={{marginBottom: 30}}>
                <div className="dance-col" style={{width: "40%"}}><img
                          alt={teacher.node.data.Name}
                          className="social-image-preview"
                          src={teacher.node.data.Headshot[0].url }
                          style={{maxWidth: "100%"}}
                        /></div>
                <div className="dance-col" style={{width: "60%", paddingLeft: 15}}>
                <div className="teacher-name">{teacher.node.data.Name}</div>



                  {teacher.node.data.Styles.map(style => {
                  return <span key={style} className="dance-style">{style}</span>
                })}

                <div style={{paddingTop: 5}}>
                {teacher.node.data.Description}

                {teacher.node.data.DiscountStatus && <span style={{display: "block", color: "#023047", fontStyle: "italic", paddingTop: 2}}>New dancers get {teacher.node.data.Discount_Amount} off first class.</span>}
                </div>
                </div>
              </div>
            </Link>

          </div>
        })}

        </section>


      </article>
    </Layout>
  )
}

export default TeacherListTemplate


export const pageQuery = graphql`
  query TeacherListByRegion(
    $regionSlug: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    teacherList: allAirtable(filter: {table: {eq: "Teachers"}, data: {Status: {eq: "Live"}, region_slug__from_Regions_: {eq: $regionSlug}}}) {
        edges {
          node {
            data {
              RecordID
              Name
              Description
              Styles
              Discount_Amount
              SortOrder
              region_slug__from_Regions_
              Headshot {
               url
              }
            }
          }
        }
      }
  }
`
